import styled from "styled-components";
import List from "../components/list";
import { useModelStore } from "../../../store/store";
import { useModelActions } from "../../../store/hooks/use-actions";
import AssetModal from "../modals/asset-modal";
import { useState } from "react";
import { Asset, SceneObject } from "../../../lib/model/ugla-filetype";
import ObjectModal from "../modals/object-modal";

interface ScenePanelProps {

}

const ScenePanel : React.FC<ScenePanelProps> = (p) => {
  const model = useModelStore(state => state.model);
  const {addObject, updateObject, removeObject} = useModelActions('objects');

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<number | undefined>();

  const {selectItem} = useModelActions('editor');
  const itemSelection = useModelStore(state => state.itemSelection);

  const handleAddObject = () => {
    setModalItem(undefined);
    setModalVisibility(true);
  }

  const handleEdit = (index : number) => {
    setModalItem(index);
    setModalVisibility(true);
  }

  const handleSubmit = (obj : SceneObject) => {
    if(typeof modalItem === 'number') {
      updateObject(modalItem, obj);
    }
    else {
      addObject(obj)
    }
  }

  const handleRemove = (index : number) => {
    if(window.confirm(`Confirmer la suppression de l'objet`)) {
      removeObject(index);
    }
  }

  const handleSelect = (index : number) => {
    selectItem({type : 'object', index})
  }

  const isSelected = (index : number) => {
    return !!itemSelection && itemSelection.type === 'object' && itemSelection.index === index
  }


  return (
    <Container>
      <List
        items={model.objects().map(obj => `[${obj.id || '-'}]`)}
        onAdd={handleAddObject}
        onEdit={handleEdit}
        onDelete={handleRemove}
        onSelect={handleSelect}
        isSelected={isSelected}

      />
      {modalVisibility && <ObjectModal obj={modalItem !== undefined ? model.objects()[modalItem] : undefined} onClose={() => setModalVisibility(false)} onSubmit={handleSubmit}/>}
    </Container>
  )
}

export default ScenePanel;

const Container = styled.div`
`