import { useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import { useThree, useFrame } from "@react-three/fiber";
import { DEFAULT_VIEW_HEIGHT } from "./constants";


interface WalkControllerProps {
  canvasRef : React.RefObject<HTMLCanvasElement>;
  height ?: number;
  defaultPosition ?: [number, number];
  onChange ?: (position : THREE.Vector3, rotation : THREE.Euler, fov : number) => void;
}

const WalkController : React.FC<WalkControllerProps> = (p) => {
  const defaultCameraPosition = new THREE.Vector3(
    p.defaultPosition?.[0] || 0,
    typeof p.height === 'number' ? p.height : DEFAULT_VIEW_HEIGHT,
    p.defaultPosition?.[1] || 0
  )

  // Screen coordinates in px of the point where a drag action started. Used to determine relative movements
  const dragStart = useRef<[number, number] | undefined>();
  // Position and rotation in 3D space at the moment of drag start. Used to determine relative movements
  const startRotation = useRef<number>(0);
  const startPosition = useRef<THREE.Vector3>(defaultCameraPosition)
  // Position and rotation in 3D space during drag.
  const rotation = useRef<number>(0);
  const position = useRef<THREE.Vector3>(defaultCameraPosition)

  const lastUpdatedRotation = useRef<number>(0);
  const lastUpdatedPosition = useRef<THREE.Vector3>(new THREE.Vector3());

  const camera = useThree(state => state.camera as THREE.PerspectiveCamera);
  useFrame(() => {
    let updated = false;

    if(
      lastUpdatedPosition.current.x !== position.current.x ||
      lastUpdatedPosition.current.y !== position.current.y ||
      lastUpdatedPosition.current.z !== position.current.z
    ) {
      camera.position.set(position.current.x, position.current.y, position.current.z);
      lastUpdatedPosition.current = position.current;
      updated = true;
    }

    if(lastUpdatedRotation.current !== rotation.current) {
      camera.rotation.set(0, rotation.current, 0)
      lastUpdatedRotation.current = rotation.current;
      updated = true;
    }

    if(updated) {
      p.onChange?.(position.current, new THREE.Euler(0, rotation.current, 0), camera.fov);
    }
  })


  const handleDragStart = (x : number, y : number) => {
    dragStart.current = [x, y];
    startRotation.current = rotation.current;
    startPosition.current = position.current;
  }
  const handleMouseDown = (e : MouseEvent) => {
    handleDragStart(e.clientX, e.clientY)
  }
  const handleTouchStart = (e : TouchEvent) => {
    if(e.touches[0]) {
      handleDragStart(e.touches[0]?.clientX, e.touches[0]?.clientY)
    }
  }

  const handleDragEnd = () => {
    dragStart.current = undefined;
  }
  const handleMouseUp = () => {
    handleDragEnd()
  }
  const handleTouchEnd = () => {
    handleDragEnd()
  }

  const handleDrag = (x : number, y : number) => {
    const width = p.canvasRef.current?.clientWidth;
    const height = p.canvasRef.current?.clientHeight;

    if(!width || !height) {
      console.warn('Unable to determine client width and height. Aborting drag');
      return;
    }

    if(dragStart.current) {
      const deltaX =  (x - dragStart.current[0]) / width * 4;
      rotation.current = startRotation.current + deltaX;

      const deltaY = (y - dragStart.current[1]) / height;

      const directionVector = new THREE.Vector3(Math.sin(rotation.current), 0, Math.cos(rotation.current)).multiplyScalar(-deltaY*3)

      const newPosition = startPosition.current.clone().add(directionVector);

      position.current = newPosition;
    }
  }
  const handleMouseMove = (e : MouseEvent) => {
    handleDrag(e.clientX, e.clientY)
  }

  const handleTouchMove = (e : TouchEvent) => {
    if(e.touches[0]) {
      handleDrag(e.touches[0].clientX, e.touches[0].clientY)
    }
  }

  const handleWheel = (e : WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const delta = e.deltaY;

    const directionVector = new THREE.Vector3(Math.sin(rotation.current), 0, Math.cos(rotation.current)).multiplyScalar(delta/10)

    const newPosition = position.current.clone().add(directionVector);

    position.current = newPosition;
  }

  useEffect(() => {
    if(p.canvasRef.current) {
      p.canvasRef.current.addEventListener('mousedown', handleMouseDown);
      p.canvasRef.current.addEventListener('mouseup', handleMouseUp);
      p.canvasRef.current.addEventListener('mousemove', handleMouseMove);
      p.canvasRef.current.addEventListener('mouseleave', handleMouseUp);
      p.canvasRef.current.addEventListener('touchstart', handleTouchStart);
      p.canvasRef.current.addEventListener('touchend', handleTouchEnd);
      p.canvasRef.current.addEventListener('touchcancel', handleTouchEnd);
      p.canvasRef.current.addEventListener('touchmove', handleTouchMove);
      p.canvasRef.current.addEventListener('wheel', handleWheel);
    }

    return () => {
      if(p.canvasRef.current) {
        p.canvasRef.current.removeEventListener('mousedown', handleMouseDown);
        p.canvasRef.current.removeEventListener('mouseup', handleMouseUp);
        p.canvasRef.current.removeEventListener('mousemove', handleMouseMove);
        p.canvasRef.current.removeEventListener('mouseleave', handleMouseUp);
        p.canvasRef.current.removeEventListener('touchstart', handleTouchStart);
        p.canvasRef.current.removeEventListener('touchend', handleTouchEnd);
        p.canvasRef.current.removeEventListener('touchcancel', handleTouchEnd);
        p.canvasRef.current.removeEventListener('touchmove', handleTouchMove);
        p.canvasRef.current.removeEventListener('wheel', handleWheel);
      }
    }
  }, [p.canvasRef.current])


  return null;
}

export default WalkController;