import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import TextSelect from "../components/text-select";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useState } from "react";
import Vec3Input from "../components/vec3-input";
import TextInput from "../components/text-input";
import TextButton from "../components/text-button";
import { Vec3 } from "../../../lib/model/ugla-filetype";

const EditorControls : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const defaultControls = model.defaultControls();

  const {setDefaultControls, clearDefaultControls} = useModelActions('controls');

  const [type, setType] = useState<string | undefined>(defaultControls?.type);
  const [walkDefaults, setWalkDefaults] = useState<Vec3 | undefined>();

  if(!itemSelection || itemSelection.type !== 'controls') {return null;}

  const handleChange = (type : string | undefined, walkDefaults : Vec3 | undefined) => {
    if(type === 'orbit') {
      setDefaultControls({type});
    }
    else if(type === 'walk') {
      setDefaultControls({
        type,
        defaultPosition : walkDefaults ? [walkDefaults[1], walkDefaults[2]] : undefined,
        height : walkDefaults ? walkDefaults[0] : undefined
      })
    }
  }

  const handleTypeChange = (type : string) => {
    setType(type);
    handleChange(type, walkDefaults);
  }

  const handleWalkChange = (v : Vec3) => {
    setWalkDefaults(v);
    handleChange(type, v);
  }


  return (
    <Container>
      <Typography variant="mediumTitle">Default controls</Typography>
      <div>
        <StyledButton onClick={clearDefaultControls}>Delete</StyledButton>
      </div>
      <TextSelect
        options={[{value : 'orbit', name : 'orbit'}, {value : 'walk', name : 'walk'}]}
        value={type}
        onChange={handleTypeChange}
      />
      {
        type === 'walk' &&
        <>
          <Vec3Input
            labels={["Camera height", "x", "z"]}
            onChange={handleWalkChange}
            value={walkDefaults}
          />
        </>
      }

    </Container>
  )
}

export default EditorControls;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text}
`

const StyledButton = styled(TextButton)`
  margin-top: 1rem;
`
