import Layout from "../components/layout/layout";
import Scene from "../components/controls/panels/scene";
import Output from "../components/controls/panels/output";
import Navigator from "../components/controls/panels/navigator";
import Editor from "../components/controls/panels/editor";
import DownloadButton from "../components/controls/tools/download-button";
import UploadButton from "../components/controls/tools/upload-button";
import ConnectButton from "../components/controls/tools/connect-button";

const MainPage = () => {
  return (
    <Layout
      left={<Navigator />}
      bottom={<Output />}
      right={<Editor />}
      tools={[
        <DownloadButton key="DownloadButton"/>,
        <UploadButton key="UploadButton"/>,
        <ConnectButton key="ConnectButton"/>
      ]}
    >
      <Scene />
    </Layout>
  )
}

export default MainPage;
