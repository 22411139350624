import { ReactNode, useState } from "react";
import styled from "styled-components";
import Draggable from "./draggable";
import colors from "../../contants/colors";

interface LayoutProps {
  children : ReactNode;
  left ?: ReactNode;
  right ?: ReactNode;
  bottom ?: ReactNode;
  tools ?: ReactNode;
}

const Layout : React.FC<LayoutProps>= (p) => {
  const [bottomPanelHeight, setBottomPanelHeight] = useState<number>(parseInt(localStorage.getItem('ugla-file-editor-bottom-panel') || '250'));
  const [leftPanelWidth, setLeftPanelWidth] = useState<number>(parseInt(localStorage.getItem('ugla-file-editor-left-panel') || '150'));
  const [rightPanelWidth, setRightPanelWidth] = useState<number>(parseInt(localStorage.getItem('ugla-file-editor-right-panel') || '150'));

  return (
    <Container>
      <ToolBox>
        {p.tools}
      </ToolBox>
      <Editor>
        <LeftPanel style={{width : `${leftPanelWidth}px`}}>
          {p.left}
        </LeftPanel>
        <Draggable pos={leftPanelWidth} onChange={setLeftPanelWidth} onDragEnd={width => {localStorage.setItem('ugla-file-editor-left-panel', `${width}`)}}/>
        <MainPanel>
          <Content>
            {p.children}
          </Content>
          <Draggable horizontal negative pos={bottomPanelHeight} onChange={setBottomPanelHeight} onDragEnd={height => {localStorage.setItem('ugla-file-editor-bottom-panel', `${height}`)}}/>
          <BottomPanel style={{height : `${bottomPanelHeight}px`}}>
            {p.bottom}
          </BottomPanel>
        </MainPanel>
        <Draggable negative pos={rightPanelWidth} onChange={setRightPanelWidth} onDragEnd={width => {localStorage.setItem('ugla-file-editor-right-panel', `${width}`)}}/>
        <RightPanel style={{width : `${rightPanelWidth}px`}}>
          {p.right}
        </RightPanel>
      </Editor>
    </Container>
  )
}

export default Layout;

const Container = styled.div`
  position : absolute;
  width : 100%;
  height : 100%;
  display: flex;
  flex-direction: column;
`

const ToolBox = styled.div`
  background-color: ${colors.background};
  height : 3rem;
  display: flex;
`

const Editor = styled.div`
  flex : 1;
  display: flex;
`

const LeftPanel = styled.div`
  position : relative;
  width : 20%;
`

const RightPanel = styled.div`
  position : relative;
  width : 20%;
`

const MainPanel = styled.div`
  flex : 1;
  display: flex;
  flex-direction: column;
`

const BottomPanel = styled.div`
  height : 20%;
  position : relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Content = styled.div`
  flex : 1;
  position: relative;
`