import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer'

import {ViewerStore, ObjectsTreeNode, ObjectsTreeValue} from './store-types';
import { ObjectPath } from '../model/ugla-filetype';
import * as THREE from 'three'


export const useViewerStore = create<ViewerStore>()(
  immer<ViewerStore>(
    (set, get) => ({
      native : {},
      actions : {
        model : {
          addNative : (id : string, native : THREE.Group<THREE.Object3DEventMap>) => {
            set(state => {
              state.native[id] = native;
            })
            get().actions.model.updateObjectsTree();
          },

          updateObjectsTree : () => {
            const list = Object.entries(get().native);

            list.forEach(([objectId, group]) => group.userData.objectPath = {objectId, path : []});

            const iterate = (obj : THREE.Object3D<THREE.Object3DEventMap>, objectPath : ObjectPath) : ObjectsTreeNode => {
              obj.userData.objectPath = objectPath;
              return {
                label : obj.name,
                value : {obj, path : objectPath},
                children : (obj.children || []).map((child, index) => iterate(child, {objectId : objectPath.objectId, path : [...objectPath.path, index]}))
              }
            }

            const tree : ObjectsTreeNode = {
              label : 'UglaScene',
              value : {path : {objectId : '', path : []}},
              children : list.map(([objectId, group]) => ({
                label : `(${objectId}) ${group.name}`,
                value : {obj : group, path : {objectId, path : []}},
                children : (group.children || []).map((child, index) => iterate(child, {objectId, path : [index]}))
              }))
            }

            set(state => {state.objectsTree = tree});
          },

          getObjectFromPath : (path : ObjectPath) => {
            const root = get().objectsTree;
            if(!root) {return undefined;}
            const iterate = (node : ObjectsTreeNode) : ObjectsTreeValue | undefined => {
              if(
                node.value.path.objectId === path.objectId &&
                node.value.path.path.length === path.path.length &&
                node.value.path.path.every((i, pos) => i === path.path[pos])
              ) {
                return node.value;
              }

              const children = (node.children || []);
              for(let child of children) {
                const found : ObjectsTreeValue | undefined = iterate(child);
                if(found) {return found;}
              }

              return undefined;
            }
            return iterate(root);
          }

        }
      }
    })
  )
)
