import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from 'three';
import { Immutable } from 'immer';
import { Asset, Interaction, ObjectPath, TouchInteraction, UglaFile } from "../model/ugla-filetype";
import { useViewerActions } from "../store/hooks/use-actions";
import { useViewerStore } from "../store/store";
import { DEFAULT_MARKER } from "./constants";


type Marker = {
  center : THREE.Vector3,
  mesh : THREE.Mesh,
  interaction : TouchInteraction<Asset>,
  path : ObjectPath,
}

interface OverlayProps {
  interactions ?: Immutable<Interaction<Asset>[]>
  onInteraction ?: (i : Interaction<Asset>) => void;
}

const Overlay : React.FC<OverlayProps> = (p) => {
  const camera = useThree(state => state.camera);
  const scene = useViewerStore(state => Object.values(state.native))
  // const _scene = useThree(state => state.scene);

  const {getObjectFromPath} = useViewerActions('model');

  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markersVisibility, setMarkersVisibility] = useState<boolean[]>([]);
  const [materials, setMaterials] = useState<Record<string, THREE.SpriteMaterial>>({});

  useEffect(() => {
    const markers : Marker[] = (p.interactions || []).filter((i) : i is TouchInteraction<Asset> => i.type === 'touch').map(i => {
      if(!i.path) {return;}

      const obj = getObjectFromPath(i.path);
      const threeObject = obj?.obj;
      const path = obj?.path;

      if(threeObject) {
        const boundingBox = new THREE.Box3().setFromObject(threeObject);
        var center = new THREE.Vector3();
        boundingBox.getCenter(center);
        // threeObject.localToWorld( center );

        // const geometry = new THREE.SphereGeometry( 0.1, 32, 16 ); 
        // const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } ); 
        // const sphere = new THREE.Mesh( geometry, material ); _scene.add( sphere );
        // sphere.position.set(center.x, center.y, center.z)

        // threeObject.geometry.computeBoundingBox();
        // const geometry = threeObject.geometry;
        // var center = new THREE.Vector3();
        // geometry.boundingBox?.getCenter( center );
        // threeObject.localToWorld( center );

        return {
          center,
          mesh: threeObject,
          interaction : i,
          path : path
        };
      }
    }).filter((m) : m is Marker => !!m)

    setMarkers(markers);

    const updatedMaterials = {...materials};
    markers.forEach(marker => {
      const url = marker.interaction.marker?.url || DEFAULT_MARKER;
      if(!updatedMaterials[url]) {
        const map = new THREE.TextureLoader().load(url);
        updatedMaterials[url] = new THREE.SpriteMaterial({map, depthTest : false, depthWrite : false})
      }
    })

    setMaterials(updatedMaterials);
  }, [p.interactions]);

  const cameraPosition = useRef<THREE.Vector3>(new THREE.Vector3());
  const cameraRotation = useRef<THREE.Euler>(new THREE.Euler());
  const currentMarkers = useRef<Marker[]>([]);

  useFrame(() => {
    if(camera.position.equals(cameraPosition.current) && camera.rotation.equals(cameraRotation.current) && currentMarkers.current === markers) {
      return;
    }

    cameraPosition.current = camera.position.clone();
    cameraRotation.current = camera.rotation.clone();
    currentMarkers.current = markers;

    const visibility : boolean[] = [];

    markers.forEach((marker) => {
      const obj = marker.mesh;

      const v = new THREE.Vector3()
      v.copy(marker.center).sub(camera.position).normalize();

      const raycaster = new THREE.Raycaster();

      raycaster.set(camera.position, v)

      const intersection = raycaster.intersectObjects(scene, true);
      const intersectionUuid = (o : THREE.Object3D<THREE.Object3DEventMap>) : string[] => {
        return [o.uuid, ...o.children.map(o => intersectionUuid(o)).reduce((c : string[], n : string[]) => [...c, ...n], [])]
      }
      const uuids = intersection.map(i => intersectionUuid(i.object)).reduce((c : string[], n : string[]) => [...c, ...n], []);

      visibility.push(uuids[0] === obj.uuid);

      // let parentsUuid = [];
      // let o : THREE.Object3D<THREE.Object3DEventMap> | null= obj;
      // for(let i = 0; i < 10; i++ ){
      //   parentsUuid.push(o.name + ' ' + o.uuid);
      //   o = o.parent;
      //   if(!o){break}
      // }
      // console.log(parentsUuid, uuids)
    })

    setMarkersVisibility(visibility)
    // console.log(markers, visibility)
  })

  return (
    <>
      {markers.map((o, index) => markersVisibility[index] ? (
        <sprite
          key={index}
          material={materials[o.interaction.marker?.url || ''] || materials[DEFAULT_MARKER]}
          position={o?.center}
          onClick={() => p.onInteraction?.(o.interaction)}
        />
      ) : null)}
    </>
  )
}

export default Overlay;