import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import TextInput from "../components/text-input";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import TextButton from "../components/text-button";
import { useState } from "react";
import Vec3Input from "../components/vec3-input";
import { Vec3 } from "../../../lib/model/ugla-filetype";
import { useModelActions } from "../../../store/hooks/use-actions";

const EditorDefaultPosition : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const defaultPosition = model.camera()?.defaultPosition;
  const {setDefaultPosition, clearDefaultPosition} = useModelActions('camera');

  if(!itemSelection || itemSelection.type !== 'camera' || itemSelection.item !== 'position') {return null;}

  return (
    <Container>
      <Typography variant="mediumTitle">Default position</Typography>
      <Vec3Input value={defaultPosition} onChange={setDefaultPosition} onDelete={clearDefaultPosition}/>
    </Container>
  )
}

export default EditorDefaultPosition;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text}
`
