import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import TextInput from "../components/text-input";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import TextButton from "../components/text-button";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useState } from "react";
import List from "../components/list";
import TextSelect from "../components/text-select";
import { Action, AssetRef, Interaction, InteractionType, Modification, ObjectPath, SceneObject, Tag } from "../../../lib/model/ugla-filetype";
import ObjectPathInput from "../components/object-path-input";
import TagsInput from "../components/tags-input";
import Tabs from "../components/tabs";
import ModInput from "../components/mod-input";
import ModsInput from "../components/mods-input";
import AssetInput from "../components/asset-input";
import BooleanInput from "../components/boolean-input";

const InteractionTypesChoices = [{value : 'hidden', name : 'hidden'}, {value : 'touch', name : 'touch'}];
const ActionTypesChoices = [{
  value : 'interaction-mode',
  name : 'interaction-mode',
} , {
  value : 'show',
  name : 'show',
} , {
  value : 'hide',
  name : 'hide',
} , {
  value : 'toggle',
  name : 'toggle',
} , {
  value : 'add',
  name : 'add',
} , {
  value : 'remove',
  name : 'remove',
} , {
//   value : 'add-external',
//   name : 'add-external',
// } , {
  value : 'mod',
  name : 'mod',
} , {
  value : 'reset-mod',
  name : 'reset-mod',
} , {
//   value : 'mod-external',
//   name : 'mod-external',
// } , {
//   value : 'fit-into-view',
//   name : 'fit-into-view',
// } , {
//   value : 'reset-view',
//   name : 'reset-view',
// } , {
//   value : 'show-label',
//   name : 'show-label',
// } , {
//   value : 'hide-label',
//   name : 'hide-label',
// } , {
  value : 'animate',
  name : 'animate',
// } , {
//   value : 'focus',
//   name : 'focus',
// } , {
//   value : 'blur',
//   name : 'blur',
}]

const EditorInteractions : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const interactionGroup = itemSelection?.type === 'interactions' ? itemSelection.id : '';
  const model = useModelStore(state => state.model);
  const interactions = model.interactions(interactionGroup);
  const {setInteraction, clearInteraction, updateInteraction} = useModelActions('interactions');
  const [selectedInteraction, setSelectedInteraction] = useState<number>(-1);
  const [selectedAction, setSelectedAction] = useState<number>(-1);

  const interaction : Interaction | undefined = interactions?.[selectedInteraction];
  const action : Action | undefined = interaction?.actions?.[selectedAction];

  if(!itemSelection || itemSelection.type !== 'interactions') {return null;}

  const handleAddInteraction = () => {
    setInteraction(interactionGroup, {
      id : 'Interaction-' + (interactions.length + 1),
      type : 'hidden',
      actions : []
    })
  }

  const handleRemoveInteraction = (index : number) => {
    if(window.confirm(`Confirmer la suppression de l'interaction '${interactions[index].id}'`)) {
      clearInteraction(interactionGroup, interactions[index].id);
    }
  }

  const handleChangeInteractionId = (id : string) => {
    if(interactions[selectedInteraction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        id
      })
    }
  }

  const handleChangeInteractionType = (type : any) => {
    if(interactions[selectedInteraction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        type
      })
    }
  }

  const handleChangeInteractionPath = (path : ObjectPath) => {
    if(interactions[selectedInteraction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        // @ts-ignore
        path
      })
    }
  }

  const handleChangeInteractinMarker = (asset : AssetRef) => {
    if(interactions[selectedInteraction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        // @ts-ignore
        marker : asset
      })
    }
  }

  const handleAddAction = () => {
    updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
      ...interactions[selectedInteraction],
      actions : [...(interactions[selectedInteraction].actions || []), {
        type : "interaction-mode",
        name : ''
      }]
    })

  }

  const handleRemoveAction = (index : number) => {
    if(window.confirm(`Confirmer la suppression de l'action`)) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : [...(interactions[selectedInteraction].actions || [])].filter((_, i) => index !== i)
      })
    }
  }

  const handleChangeActionType = (type : any) => {
    if(interactions[selectedInteraction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((action, index) => {
          return index !== selectedAction ? action : {
            type,
            ...(type === 'add' ? {
              object : {}
            } : {})
          }
        })
      })
    }
  }

  const handleChangeActionParamName = (name : string) => {
    if(interactions[selectedInteraction]?.actions?.[selectedAction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
          return index !== selectedAction ? _action : {
            ..._action,
            name
          }
        })
      })
    }
  }

  const handleChangeActionParamPath = (path : ObjectPath) => {
    if(interactions[selectedInteraction]?.actions?.[selectedAction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
          return index !== selectedAction ? _action : {
            ..._action,
            path
          }
        })
      })
    }
  }

  const handleChangeActionParamAnimation = (animation : string) => {
    if(interactions[selectedInteraction]?.actions?.[selectedAction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
          return index !== selectedAction ? _action : {
            ..._action,
            animation
          }
        })
      })
    }
  }

  const handleChangeId = (id : string, name : string = 'id') => {
    if(interactions[selectedInteraction]?.actions?.[selectedAction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
          return index !== selectedAction ? _action : {
            ..._action,
            [name] : id
          }
        })
      })
    }
  }

  const handleChangeFlag = (value : boolean, name : string) => {
    if(interactions[selectedInteraction]?.actions?.[selectedAction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
          return index !== selectedAction ? _action : {
            ..._action,
            [name] : value
          }
        })
      })
    }
  }

  const handleChangeMods = (mods : Modification[]) => {
    if(interactions[selectedInteraction]?.actions?.[selectedAction]) {
      updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
        ...interactions[selectedInteraction],
        actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
          return index !== selectedAction ? _action : {
            ..._action,
            mods
          }
        })
      })
    }
  }

  const handleChangeActionParamObject = (patch : Partial<SceneObject>) => {
    updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
      ...interactions[selectedInteraction],
      actions : (interactions[selectedInteraction].actions || []).map((_action, index) => {
        return index !== selectedAction ? _action : {
          ..._action,
          object : {
            //@ts-ignore
            ..._action.object,
            ...patch
          }
        }
      })
    })
  }


  const updateTags = (tags : Tag[]) => {
    updateInteraction(interactionGroup, interactions[selectedInteraction].id, {
      ...interactions[selectedInteraction],
      tags
    })
  }

  const deleteTags = () => {
    const newObject = {...interactions[selectedInteraction]};
    delete newObject['tags'];

    updateInteraction(interactionGroup, interactions[selectedInteraction].id, newObject);
  }



  return (
    <Container>
      <Typography variant="mediumTitle">Interactions</Typography>
      <List
        items={interactions.map(i => `[${i.type}] ${i.id}`)}
        onAdd={handleAddInteraction}
        onDelete={handleRemoveInteraction}
        isSelected={i => selectedInteraction === i}
        onSelect={setSelectedInteraction}
      />
      {
        interaction ?
        <InteractionContainer>
          <TextInput value={interaction.id} onChange={handleChangeInteractionId} label="Nom de l'interaction"/>
          <TextSelect
            value={interaction.type}
            options={InteractionTypesChoices}
            onChange={handleChangeInteractionType}
            label="Type d'interaction"
          />
          {
            interaction.type === 'touch' ?
            <div>
              <ObjectPathInput value={interaction.path} onChange={handleChangeInteractionPath} label="Path" mode="both"/>
              <AssetInput value={interaction.marker} onChange={handleChangeInteractinMarker} label="Marker" type="2D" />
            </div> :
            null
          }
          <br />
          <Tabs names={['Actions', 'Tags']}  key={interaction.id}>
            {[
              <div key="Actions">
                <List
                  items={interaction.actions.map((a, index) => `(${index+1}) ${a.type}`)}
                  onAdd={handleAddAction}
                  onDelete={handleRemoveAction}
                  isSelected={i => selectedAction === i}
                  onSelect={setSelectedAction}
                />
                {
                  action ?
                  <ActionContainer>
                    <TextSelect
                      value={action.type}
                      options={ActionTypesChoices}
                      onChange={handleChangeActionType}
                      label="Type d'actions"
                    />
                    {
                      action.type === 'interaction-mode' ?
                      <TextInput value={action.name} onChange={handleChangeActionParamName} label="Mode"/>
                      : null
                    }
                    {
                      action.type === 'show' ||
                      action.type === 'hide' ||
                      action.type === 'toggle' ?
                      <ObjectPathInput value={action.path} onChange={handleChangeActionParamPath} label="Path" mode="both"/>
                      : null
                    }
                    {
                      action.type === 'add' ?
                      <div>
                        <TextInput value={action.object.id} onChange={id => handleChangeActionParamObject({id})} label="ObjectId"/>
                        <AssetInput value={action.object.asset} onChange={asset => handleChangeActionParamObject({asset})} />
                        <Typography variant="mediumTitle">Mods</Typography>
                        <ModsInput value={action.object.mods} onChange={mods => handleChangeActionParamObject({mods})}/>
                      </div>
                      : null
                    }
                    {
                      action.type === 'remove' ?
                      <div>
                        <TextInput value={action.objectId} onChange={id => handleChangeId(id, 'objectId')} label="ObjectId"/>
                        <BooleanInput value={action.regex} onChange={regex => handleChangeFlag(regex, 'regex')} label="Regex"/>
                      </div>
                        : null
                    }
                    {
                      action.type === 'fit-into-view' ||
                      action.type === 'show-label' ||
                      action.type === 'hide-label' ||
                      action.type === 'animate' ||
                      action.type === 'focus' ?
                      <ObjectPathInput value={action.path} onChange={handleChangeActionParamPath} label="Path"/>
                      : null
                    }
                    {
                      action.type === 'animate' ?
                      <TextInput value={action.animation} onChange={handleChangeActionParamAnimation} label="Animation"/>
                      : null
                    }
                    {
                      action.type === 'mod' ?
                      <div>
                        <TextInput value={action.id} onChange={handleChangeId} label="Modifications ID"/>
                        <ModsInput value={action.mods} onChange={handleChangeMods}/>
                      </div> :
                      null
                    }
                    {
                      action.type === 'reset-mod' ?
                      <div>
                        <TextInput value={action.id} onChange={handleChangeId} label="Modifications ID"/>
                        <BooleanInput value={action.regex} onChange={regex => handleChangeFlag(regex, 'regex')} label="Regex"/>
                      </div> :
                      null
                    }
                  </ActionContainer> :
                  null
                }
              </div>,
              <TagsContainer key="Tags">
                <Typography variant="mediumTitle">Tags</Typography>
                <TagsInput value={interaction.tags} onChange={updateTags} onDelete={deleteTags}/>
              </TagsContainer>
            ]}
          </Tabs>
        </InteractionContainer> :
        null
      }
    </Container>
  )
}

export default EditorInteractions;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text};
`

const InteractionContainer = styled.div`
  margin-top : 1rem;
`

const ActionContainer = styled.div`
  margin-top : 1rem;
`

const TagsContainer = styled.div`
  margin-top : 1rem;
`