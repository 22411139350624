import React, { Suspense, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei'
import styled from 'styled-components'
import md5 from 'md5';
import Ugla3D from '../model/ugla-3d';
import SceneObject from './scene-object';
import WalkController from './walk-controller';
import { Asset, Control, Interaction, ObjectPath, Vec3 } from '../model/ugla-filetype';
import Overlay from './overlay';
import { EffectComposer, Outline, Selection, Select } from '@react-three/postprocessing';
import { useViewerActions } from '../store/hooks/use-actions';
import { useCameraState, useControlState, useDefaultCamera, useInteractionsState, useModsState, useSceneState, useUgla3dStateManager, useVisibilityState } from '../model-state/use-model-state';
import InterfaceObject from './interface-object';

interface ViewerProps {
  onClick ?: (e : any) => void;
  selection ?: ObjectPath[];
  scale ?: number;
}

const Viewer : React.FC<ViewerProps> = (p) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const objects = useSceneState();
  const [control, setControl] = useControlState();
  const defaultCamera = useDefaultCamera();
  const [cameraState, setCameraState] = useCameraState();
  const [controls] = useControlState();
  const interactions = useInteractionsState();
  const stateManager = useUgla3dStateManager();
  const mods = useModsState();
  const visibility = useVisibilityState();

  const objects3D = objects.filter(o => o.asset.type === '3D');
  const objects2D = objects.filter(o => o.asset.type === '2D');

  const { getObjectFromPath } = useViewerActions('model');

  // Refresh the viewer when default parameters changes
  const refreshKey = control;

  const selectedObject = p.selection ?
    p.selection
      .map(objPath => getObjectFromPath(objPath)?.obj)
      .filter((o) : o is THREE.Mesh => !!o && o instanceof THREE.Mesh) :
    [];


  // Update camera state according to control values
  const handleCameraChange = (position : THREE.Vector3, rotation : THREE.Euler, fov : number) => {
    setCameraState({
      position : [position.x, position.y, position.z],
      rotation : [rotation.x, rotation.y, rotation.z],
      fov
    })
  }

  const handleInteraction = (interaction : Interaction<Asset>) => {
    stateManager.interact(interaction.id);
  }

  return (
    <Container>
      <Canvas
        key={refreshKey}
        ref={canvasRef}
        camera={defaultCamera}
        style={{height : '100%', width : '100%', position : 'absolute'}}
      >
        {
          /*
            Light parameters (TO BE MODIFIED)
          */
        }
        <ambientLight intensity={Math.PI / 2} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
        <pointLight castShadow position={[-10, -10, -10]} decay={0} intensity={Math.PI} />

        {
          /*
            GLTF or GLB objects
          */
        }
        {
          objects3D.map(obj => (
            <Suspense key={obj.id}>
              <SceneObject
                key={obj.id}
                object={obj}
                mods={mods}
                visibility={visibility}
                onClick={p.onClick}
              />
            </Suspense>
          ))
        }
        {
          controls === 'orbit' ?
            <OrbitControls /> :
          !controls || controls === 'walk' ?
            <WalkController
              canvasRef={canvasRef}
              defaultPosition={[defaultCamera.position[0], defaultCamera.position[2]]}
              height={defaultCamera.position[1]}
              onChange={handleCameraChange}
            /> :
            null
        }
        <Overlay
          interactions={interactions}
          onInteraction={handleInteraction}
        />
        {
          selectedObject.length ?
          <EffectComposer multisampling={8} autoClear={false}>
            <Outline
              selection={selectedObject}
              visibleEdgeColor={0x4444FF}
              hiddenEdgeColor={0x4444FF}
              edgeStrength={10000}
            />
          </EffectComposer> :
          null
        }
      </Canvas>
      <Interface>
        {
          objects2D.map(obj => (
            <InterfaceObject
              key={obj.id}
              object={obj}
              scale={p.scale}
              mods={mods}
              visibility={visibility}
              interactions={interactions}
              onInteraction={handleInteraction}
              />
          ))
        }
      </Interface>
    </Container>
  )
}

export default Viewer;

const Container = styled.div`
  position : relative;
  height : 100%;
  width : 100%;
  user-select: none;
`

const Interface = styled.div`
  position: absolute;
  height : 100%;
  width : 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 0;
`