import styled from "styled-components";
import { Modification, Tag, Vec3 } from "../../../lib/model/ugla-filetype";
import TextInput from "./text-input";
import { useState } from "react";
import TextButton from "./text-button";
import ModInput from "./mod-input";
import Vec3Input from "./vec3-input";
import Typography from "./typography";
import Vec2Input from "./vec2-input";
import Vec1Input from "./vec1-input";

interface ModsInputProps {
  value ?: Modification[];
  onChange ?: (value : Modification[]) => void;
  onDelete ?: () => void;
}

const ModsInput : React.FC<ModsInputProps> = (p) => {
  const handleDelete = () => {
    if(p.onDelete) {
      p.onChange?.([]);
      p.onDelete();
    }
  }

  // const handleUpdateKey = (index : number, value : string) => {
  //   if(!p.value?.[index]) {return;}

  //   const newTags = [...p.value];
  //   newTags[index] = {
  //     key : value,
  //     value : p.value[index]?.value
  //   }

  //   p.onChange?.(newTags);
  // }

  const handleUpdate = (index : number, value : Modification) => {
    if(!p.value?.[index]) {return;}

    const newMods = [...p.value];
    newMods[index] = value

    p.onChange?.(newMods);
  }

  const handleRemove = (index : number) => {
    if(!p.value?.[index]) {return;}

    const newMods = [...p.value];
    newMods.splice(index, 1);

    p.onChange?.(newMods);
  }

  const handleNew = () => {
    p.onChange?.([...(p.value || []), {type : '2D', path : {objectId : '-', path:[]}}])
  }

  return (
    <Container>
      {
        (p.value || []).map((mod, index) => (
          <ModContainer key={index}>
            <ModInput
              value={mod}
              onChange={(value) => handleUpdate(index, value)}
              onDelete={() => handleRemove(index)}
            />
          </ModContainer>
        ))
      }
      <ButtonsContainer>
        <TextButton onClick={handleNew}>+ Add</TextButton>&nbsp;
        {
          p.onDelete ?
              <TextButton onClick={handleDelete}>Delete all</TextButton> :
            null
        }
      </ButtonsContainer>
    </Container>
  )
}

export default ModsInput;

const Container = styled.div`
`

const ButtonsContainer = styled.div`
  margin-top : 3rem;
  text-align: right;
`
const ModContainer = styled.div`

`