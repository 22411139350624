import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import ArchiveIcon from "../../../assets/icons/archive";

const DownloadButton : React.FC<{}> = (p) => {
  const model = useModelStore(state => state.model);

  const handleDownload = () => {
    var fileContent = JSON.stringify(model.getContent());
    var bb = new Blob([fileContent ], { type: 'application/json' });
    var a = document.createElement('a');
    a.download = 'export.ugla';
    a.href = window.URL.createObjectURL(bb);
    a.click();
  }

  return (
    <>
      <Button><ArchiveIcon onClick={handleDownload}/></Button>
    </>
  )
}

export default DownloadButton;

const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`
