import styled from "styled-components";
import UnarchiveIcon from "../../../assets/icons/unarchive";
import { useRef } from "react";
import { useModelActions } from "../../../store/hooks/use-actions";

const UploadButton : React.FC<{}> = (p) => {
  const {load} = useModelActions('model');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    fileInputRef.current?.click();
  }

  const handleUpload = (file : File) => {
    var reader = new FileReader();
    reader.onload = (e : ProgressEvent<FileReader>) => {
      try {
        load(`${e.target?.result}`)
      }
      catch(error) {
        alert('Invalid file format')
      }
    };
    reader.readAsText(file);
  }

  return (
    <>
      <Button><UnarchiveIcon onClick={handleClickUpload}/></Button>
      <FileInput
        ref={fileInputRef}
        type="file"
        accept=".ugla"
        onChange={e => e.target.files?.[0] && handleUpload(e.target.files?.[0])}
      />
    </>
  )
}

export default UploadButton;


const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`
