import { useModelStore } from "../../../store/store";
import Viewer from "../../../lib/viewer/viewer"
import * as THREE from 'three';
import axios from "axios";
import { useModelActions } from "../../../store/hooks/use-actions";
import { Interaction } from "../../../lib/model/ugla-filetype";
import { useEffect, useState } from "react";
import Responsive from "../components/responsive";

const Scene : React.FC<{}> = (p) => {
  const connection = useModelStore(state => state.connection);
  const selectionActive = useModelStore(state => state.objectSelection.active);
  const selectedPaths = useModelStore(state => state.objectSelection.selected);
  const {selectObject} = useModelActions('editor');
  const [scale, setScale] = useState<number>(1);


  // useEffect(() => {
  //   if(connection.running) {
  //     const assets = model.assets();
  //     model.getContent().scene.forEach(obj => {
  //       const asset = assets[obj.asset];
  //       const id = obj.id;

  //       if(asset.type === '3D') {
  //         axios.post(`${connection.viewerUrl}/3dapp/media3d/`, {
  //           "id" : id,
  //           "type": "media3d",
  //           "layer": 2,
  //           "pathToImage": "http://192.168.40.236:3000" + asset.url,
  //           "position": {
  //               "x": 0,
  //               "y": 0,
  //               "z": 0
  //           },
  //           "scale": {
  //               "x": 1,
  //               "y": 1,
  //               "z": 1
  //           },
  //           "rotation": {
  //               "x": 0,
  //               "y": 0,
  //               "z": 0
  //           }
  //         })
  //       }
  //     })
  //   }
  // }, [connection.running])

  // const handleCamera = (position : THREE.Vector3, rotation : THREE.Euler, fov: number) => {
  //   if(connection.running) {
  //     axios.post(`${connection.viewerUrl}/3dapp/camera/`, {
  //       position :{
  //         x : -position.x,
  //         y : 0,
  //         z : position.z
  //       },
  //       rotation : {
  //         x : 0,
  //         y : -rotation.y / Math.PI * 180,
  //         z : 0
  //       }
  //     })
  //   }
  // }

  const handleClick = (e : any) => {
    if(connection.running) {
      // axios.post(`${connection.viewerUrl}/3dapp/animation/`, {
      //   id : 'hello world',
      //   name : 'chair'
      // })
    }
    else if(selectionActive) {
      const obj = e.object;
      const path = obj?.userData?.objectPath;
      if(obj && path) {
        selectObject(path);
      }
    }
  }

  // const handleInteraction = (i : Interaction) => {
  //   if(connection.running) {
  //     i.actions.forEach(action => {
  //       if(action.type === 'animate') {
  //         axios.post(`${connection.viewerUrl}/3dapp/animation/`, {
  //           id : action.path.objectId,
  //           name : action.animation
  //         })
  //       }
  //     })
  //   }
  // }

  return (
    <Responsive onScaleChange={setScale}>
      <Viewer
        scale={scale}
        onClick={handleClick}
        selection={selectionActive ? selectedPaths : undefined}
      />
    </Responsive>
  );
}

export default Scene;