import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import EyeIcon from "../../../assets/icons/eye";
import { useRef, useState } from "react";
import ConnectionModal from "../modals/connection-modal";
import { useModelActions } from "../../../store/hooks/use-actions";
import CloseIcon from "../../../assets/icons/close";
import { UnityViewerBridge } from "../../../lib/bridges/unity-viewer-bridge";
import { stateManager, useUgla3dState, useUgla3dStateManager } from "../../../lib/model-state/use-model-state";

const ConnectButton : React.FC<{}> = (p) => {
  // const connection = useModelStore(state => state.connection);
  // const {start, stop} = useModelActions('connection');
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const bridge = useRef<UnityViewerBridge | null>(null)
  const stateManager = useUgla3dStateManager();

  const handleStart = () => {
    if(bridge.current?.running) {
      bridge.current.stop();
      bridge.current = null;
    }
    else {
      setModalVisible(true);
    }
  }

  const handleSubmit = (viewerUrl : string, appName : string, mode : '2d' | '3d') => {
    bridge.current = new UnityViewerBridge(stateManager, {viewerUrl, appName, mode})
    bridge.current.start();
  }

  return (
    <>
      <Button onClick={handleStart}>
        {
          bridge.current?.running ?
            <CloseIcon /> :
            <EyeIcon/>
        }
      </Button>
      {modalVisible && <ConnectionModal onSubmit={handleSubmit} onClose={() => setModalVisible(false)}/>}
    </>
  )
}

export default ConnectButton;

const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`
