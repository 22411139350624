import styled from "styled-components";
import colors from "../../../contants/colors";
import EditIcon from "../../../assets/icons/edit";
import RemoveCrossIcon from "../../../assets/icons/remove-cross";

interface ListProps {
  items : string[];
  onDelete ?: (index : number) => void;
  onEdit ?: (index : number) => void;
  onAdd ?: () => void;
  isSelected ?: (index : number) => boolean;
  onSelect ?: (index : number) => void;
}


const List : React.FC<ListProps>= (p) => {
  return (
    <Container>
      {
        p.items.map((item, index) => (
          <Item key={item} className={p.isSelected?.(index) ? 'selected' :  ''} onClick={() => p.onSelect?.(index)}>
            <Label>{item}</Label>
            <Buttons onClick={e => e.stopPropagation()}>
              {
                p.onEdit ?
                <SmallButton onClick={() => p.onEdit?.(index)}>
                  <EditIcon />
                </SmallButton> :
                null
              }
              {
                p.onDelete ?
                <SmallButton  onClick={() => p.onDelete?.(index)}>
                  <RemoveCrossIcon />
                </SmallButton> :
                null
              }
            </Buttons>
          </Item>
        ))
      }
      {p.onAdd && <Item onClick={p.onAdd} style={{cursor : 'pointer'}}><Label>Add +</Label></Item>}
    </Container>
  )
}

export default List;

const Container = styled.div`

`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding : 0.3rem;
  color : ${colors.text};
  border-bottom : 0.1rem solid ${colors.backgroundLight};
  cursor : pointer;

  &.selected {
    background-color: ${colors.backgroundSelect};
  }
`

const Label = styled.div`
  flex : 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Buttons = styled.div`
  display: flex;
`

const SmallButton = styled.div`
  width : 1.1rem;
  height : 1.1rem;
  cursor : pointer;
  margin-right : 0.3rem;
`