import { ReactNode } from "react";
import styled from "styled-components";

interface TypographyProps {
  variant : 'mediumTitle' | 'label'
  className ?: string;
  children ?: ReactNode;
}

const Typography : React.FC<TypographyProps> = (p) => {
  return <Span className={(p.className || ' ') + p.variant}>{p.children}</Span>
}

export default Typography;

const Span = styled.span`
  &.mediumTitle {
    font-size: 1.5rem;
    font-weight: bolder;
    display: inline-block;
    margin-top : 1rem;
  }
`