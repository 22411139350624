import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import TagsInput from "../components/tags-input";
import { Tag } from "../../../lib/model/ugla-filetype";
import { useModelActions } from "../../../store/hooks/use-actions";

const AssetEditor : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const {updateAsset} = useModelActions('assets');

  if(!itemSelection || itemSelection.type !== 'asset') {return null;}

  const asset = model.getContent().assets[itemSelection.index];

  const updateTags = (tags : Tag[]) => {
    updateAsset(itemSelection.index, {
      ...asset,
      tags
    })
  }

  const deleteTags = () => {
    const newAsset = {...asset};
    delete newAsset['tags'];

    updateAsset(itemSelection.index, newAsset);
  }

  return (
    <Container>
      <Typography variant="mediumTitle">Tags</Typography>
      <TagsInput value={asset.tags} onChange={updateTags} onDelete={deleteTags}/>
    </Container>
  )
}

export default AssetEditor;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text};

`