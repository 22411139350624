import { assert } from "../assert-type";
import { Asset, Control, Interaction, SceneObject, UglaFile, Vec3, isAsset, isCamera, isControl, isInteraction, isSceneObject } from "./ugla-filetype";
import {cloneDeep} from 'lodash';

export default class Ugla3D {
  private content : UglaFile;

  constructor(model ?: string | Ugla3D) {
    if(model instanceof Ugla3D) {
      this.content = cloneDeep(model.content);
    }
    else if(typeof model === 'string') {
      this.content = this.importJSON(model);
    }
    else {
      this.content = {
        assets : [],
        scene : [],
        interactions : {}
      }
    }
  }

  private importJSON (json: string) {
    const parsed = JSON.parse(json);
    const content : UglaFile = {
      assets : [],
      scene : [],
      interactions : {}
    }

    const {assets, scene, camera, defaultControls, defaultInteraction, interactions} = parsed;

    const log : string[] = [];
    if(!(
      assert(Array.isArray(assets) && assets.every(a => isAsset(a, log)), '-> assets', log) &&
      assert(Array.isArray(scene) && scene.every(o => isSceneObject(o, log)), '-> scene', log) &&
      assert((camera === undefined || isCamera(camera, log)), '-> camera', log) &&
      assert((defaultControls === undefined || isControl(defaultControls, log)), '-> defaultControls', log) &&
      assert(Object.values(interactions || {}).every(i => Array.isArray(i) && i.every(ii => isInteraction(ii, log))), '-> interactions', log) &&
      assert((defaultInteraction === undefined || (typeof defaultInteraction === 'string' && !!interactions[defaultInteraction])), '-> defaultInteractions', log)
      )) {
      console.error('Invalid format:' + log.reverse().join('\n'))
      throw 'Invalid format:' + log.join('\n');
    }

    content.assets = assets.filter((a : any) : a is Asset => isAsset(a, []));
    content.scene = scene.filter((o : any) => isSceneObject(o));
    content.camera = camera;
    content.defaultControls = defaultControls;
    content.defaultInteractionsGroup = defaultInteraction;
    content.interactions = interactions;

    return content;
  }

  getContent() {
    return this.content;
  }

  assets() {
    return this.content.assets;
  }

  addAsset(asset : Asset) {
    const clone = new Ugla3D(this);
    clone.content.assets.push(asset);
    return clone;
  }

  updateAsset(index : number, asset : Partial<Asset>) {
    const clone = new Ugla3D(this);
    clone.content.assets[index] = {
      ...clone.content.assets[index],
      ...asset
    };
    return clone;
  }

  removeAsset(index : number) {
    const clone = new Ugla3D(this);
    clone.content.assets.splice(index, 1);
    return clone;
  }

  objects() {
    return this.content.scene;
  }

  addObject(obj : SceneObject) {
    const clone = new Ugla3D(this);
    clone.content.scene.push(obj);
    return clone;
  }

  updateObject(_indexOrId : number | string, obj : Partial<SceneObject>) {
    const clone = new Ugla3D(this);
    const index : number = typeof _indexOrId === 'number' ? _indexOrId : clone.content.scene.findIndex(i => i.id === _indexOrId);

    clone.content.scene[index] = {
      ...clone.content.scene[index],
      ...obj
    };

    return clone;
  }

  removeObject(_indexOrId : number | string) {
    const clone = new Ugla3D(this);
    const index : number = typeof _indexOrId === 'number' ? _indexOrId : clone.content.scene.findIndex(i => i.id === _indexOrId);

    clone.content.scene.splice(index, 1);
    return clone;
  }

  camera() {
    return this.content.camera;
  }

  setCameraDefaultPosition(value : Vec3) {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    clone.content.camera.defaultPosition = value;
    return clone;
  }

  clearCameraDefaultPosition() {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    delete clone.content.camera.defaultPosition;
    return clone;
  }

  setCameraDefaultRotation(value : Vec3) {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    clone.content.camera.defaultRotation = value;
    return clone;
  }

  clearCameraDefaultRotation() {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    delete clone.content.camera.defaultRotation;
    return clone;
  }

  setCameraDefaultFov(value : number) {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    clone.content.camera.defaultFov = value;
    return clone;
  }

  clearCameraDefaultFov() {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    delete clone.content.camera.defaultFov;
    return clone;
  }

  defaultControls () {
    return this.content.defaultControls;
  }

  setDefaultControls(controls : Control) {
    const clone = new Ugla3D(this);
    clone.content.defaultControls = controls;
    return clone;
  }

  clearDefaultControls() {
    const clone = new Ugla3D(this);
    delete clone.content.defaultControls;
    return clone;
  }

  defaultInteractionsGroup() {
    return this.content.defaultInteractionsGroup
  }

  interactions(_group ?: string) {
    const group = _group || this.content.defaultInteractionsGroup || Object.keys(this.content.interactions)[0];

    return this.content.interactions[group] || [];
  }

  interactionsGroupsNames() {
    return Object.keys(this.content.interactions);
  }

  setDefaultInteractionsGroup(name : string) {
    const clone = new Ugla3D(this);

    if(!this.content.interactions[name]) {return this;}

    clone.content.defaultInteractionsGroup = name;
    return clone;
  }

  clearDefaultInteractionsGroup() {
    const clone = new Ugla3D(this);
    delete clone.content.defaultInteractionsGroup;
    return clone;
  }

  createInteractionGroup(group : string) {
    const clone = new Ugla3D(this);

    if(!clone.content.interactions[group]) {
      clone.content.interactions[group] = [];
    }

    return clone;
  }

  deleteInteractionGroup(group : string) {
    const clone = new Ugla3D(this);

    delete clone.content.interactions[group];
    if(clone.content.defaultInteractionsGroup === group) {
      delete clone.content.defaultInteractionsGroup;
    }

    return clone;
  }

  updateInteractionGroup(previous : string, next : string) {
    const clone = new Ugla3D(this);

    if(clone.content.interactions[next]) {
      // Do not change the name of an interaction group if the target name already exist
      return this;
    }

    clone.content.interactions[next] = clone.content.interactions[previous];
    delete clone.content.interactions[previous];

    if(clone.content.defaultInteractionsGroup === previous) {
      clone.content.defaultInteractionsGroup = next;
    }

    return clone;
  }

  setInteraction(group : string, interaction : Interaction) {
    const clone = new Ugla3D(this);

    if(!clone.content.interactions[group]) {
      clone.content.interactions[group] = [];
    }

    clone.content.interactions[group] = [...clone.content.interactions[group].filter(i => i.id != interaction.id), interaction];

    return clone;
  }

  updateInteraction(group :string, id : string, interaction : Interaction) {
    const clone = new Ugla3D(this);

    if(!clone.content.interactions[group]) {
      clone.content.interactions[group] = [];
    }

    if(interaction.id !== id && clone.content.interactions[group].find(i => i.id === interaction.id)) {
      //Changing the id of an interaction with an id that is already in the array, this will cause problems.
      //Do nothing

      return this;
    }

    clone.content.interactions[group] = clone.content.interactions[group].map(i => i.id != id ? i : interaction);

    return clone;
  }

  clearInteraction(group : string, interactionId : string) {
    const clone = new Ugla3D(this);

    clone.content.interactions[group] = (clone.content.interactions[group] || []).filter(i => i.id != interactionId);

    return clone;
  }
};

