// import * as THREE from 'three'
// import { createRoot } from 'react-dom/client'
// import React, { useRef, useState } from 'react'
// import { Canvas, useFrame, ThreeElements, useLoader } from '@react-three/fiber'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// import { CameraControls, MapControls, OrbitControls } from '@react-three/drei'

import MainPage from "./pages/main";


// function Box(props: ThreeElements['mesh']) {
//   const gltf = useLoader(GLTFLoader, '/visuarydemo.glb')
//   return <primitive object={gltf.scene} onClick={(e : any) => {
//     e.stopPropagation();
//     // e.object.removeFromParent();
//   }}/>
// }


// export default function App() {
//   return (
//     <Canvas
//       style={{height : '100%', width : '100%', position : 'absolute'}}
//       camera={{position : [0, 0, 3]}}
//     >
//       <ambientLight intensity={Math.PI / 2} />
//       <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
//       <pointLight castShadow position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
//       <Box/>
//       <CameraControls />
//     </Canvas>
//   )
// }


export default function App() {
  return (
    <MainPage />
  )
}