import {clone, cloneDeep, flatten, uniqBy} from 'lodash';
import {produce, castDraft, Draft, Immutable} from "immer";

import Ugla3D from "../model/ugla-3d";
import { Asset, AssetRef, ControlType, isAssetSceneObject, isModification, Modification, Modification2D, Modification3D, ObjectPath, SceneObject, Vec2, Vec3 } from "../model/ugla-filetype";
import { StateEvent, StateEventName, Ugla3DState } from "./ugla-3d-state-type";
import { isSameObjectPath, objectPathToString } from '../model/ugla-types-tools';
import { DEFAULT_VIEW_HEIGHT } from '../viewer/constants';

const DEFAULT_POSITION : Vec3 = [0, DEFAULT_VIEW_HEIGHT, 0];
const DEFAULT_ROTATION : Vec3 = [0, 0, 0];
const DEFAULT_FOV             = 90;

const EMPTY_STATE : Ugla3DState = {
  scene : [],
  camera : {
    position : DEFAULT_POSITION,
    fov : DEFAULT_FOV,
    rotation : DEFAULT_ROTATION
  },
  control : 'walk',
  interactionsGroup : '',
  interactions : [],
  changes : {
    visibility : {
      show : [],
      hide : []
    },
    mods : {}
  }
}

type EventHandlers = {
  [Event in StateEventName] ?: ((event : Extract<StateEvent, {type : Event}>) => void)[]
}

class Ugla3DStateManager {
  private model : Ugla3D;
  private state : Immutable<Ugla3DState>;
  private eventHandlers : EventHandlers;

  constructor(model ?: Ugla3D) {
    this.model = new Ugla3D();
    this.state = cloneDeep(EMPTY_STATE);
    this.eventHandlers = {};

    if(model) {
      this.setModel(model);
    }
  }

  getState() {
    return this.state;
  }

  /**
   * Add event listener
   * @param event
   * @param handler
   */
  on<E extends StateEventName>(name : E, handler : (event : Extract<StateEvent, {type : E}>) => void) {
    this.eventHandlers[name] = [...(this.eventHandlers[name] || []), handler as any]
  }

  /**
   *  Remove event listener
   * @param name
   * @param handler
   */
  off<E extends StateEventName>(name : E, handler : (event : Extract<StateEvent, {type : E}>) => void) {
    this.eventHandlers[name] = (this.eventHandlers[name] || [] as any)?.filter((h : any) => h!== handler);
  }

  private handleEvent<E extends StateEventName>(name : E, event : Extract<StateEvent, {type : E}>) {
    if(!this.eventHandlers[name]) {
      this.eventHandlers[name] = [];
    }

    this.eventHandlers[name]?.forEach((handler) => handler(event));
  }

  // Handle immutable state change
  private change(recipe : (draft : Draft<Ugla3DState>) => void) {
    const previousState = this.state;
    this.state = produce(this.state, recipe);

    const changeEvent = {
      type : 'change',
      value : this.state,
      previous : previousState
    } as const;

    if(this.state !== previousState) {
      this.handleEvent('change', changeEvent);
    }

    return changeEvent;
  }

  setModel(model : Ugla3D, options ?: {soft ?: boolean}) {
    // Avoid state reset is model is the same object
    if(this.model !== model) {
      this.model = model;

      if(!options?.soft) {
        this.reset();
      }
    }

    return model;
  }

  reset() {
    const content = this.model.getContent();

    const defaultMods : Record<string, Modification[]> = {};

    const changeEvent = this.change((_ : Ugla3DState) => {
      const state = cloneDeep(EMPTY_STATE);

      // Scene objects
      state.scene = uniqBy(
        content.scene
        // Remove invalid scene object that refers to non existing assets
        .filter(obj => !!content.assets[obj.asset])
        // Map assets refereces to real assets
        .map((obj) => ({
          ...obj,
          asset : content.assets[obj.asset]
        })),
        // There should be no duplicated id, so remove dupes with uniqBy(_, 'id')
        'id'
      )

      state.scene.forEach(obj => {
        if(obj.mods) {
          defaultMods[this.defaultModsName(obj)] = obj.mods
        }
      })

      // Camera
      if(content.camera?.defaultPosition) {
        state.camera.position = content.camera.defaultPosition;
      }
      if(content.camera?.defaultRotation) {
        state.camera.rotation = content.camera.defaultRotation;
      }
      if(content.camera?.defaultFov) {
        state.camera.fov = content.camera.defaultFov;
      }

      // Control
      if(content.defaultControls) {
        state.control = content.defaultControls.type;
      }

      return state;
    })

    this.handleEvent('reset', {
      type : 'reset',
      value : changeEvent.value,
      previous : changeEvent.previous
    });


    // Interactions
    this.selectInteractionsGroup(content.defaultInteractionsGroup || Object.keys(content.interactions)[0] || '');
    this.handleEvent('camera', {
      type : 'camera',
      value : this.state.camera
    });
    this.handleEvent('scene', {
      type : 'scene',
      value : this.state.scene,
      added : this.state.scene,
      removed : changeEvent.previous.scene,
    });
    this.handleEvent('mods', {
      type : 'mods',
      value : flatten(Object.values(this.state.changes.mods)),
      added : flatten(Object.values(this.state.changes.mods)),
      removed : flatten(Object.values(changeEvent.previous.changes.mods)),
    });
    // Add default mods after sending the mods event, otherwise mods events are sent twice
    // The previous call to handleEvent does not trig transmission of mods to the bridge,
    // since the state mods are empty.
    Object.entries(defaultMods).forEach(([modsId, mods]) => this.setMods(modsId, mods));

    this.handleEvent('visibility', {
      type : 'visibility',
      value : this.state.changes.visibility,
      added : this.state.changes.visibility,
      removed : changeEvent.previous.changes.visibility,
    });
  }

  setCameraPosition(position : Immutable<Vec3>) {
    this.change((state : Ugla3DState) => {
      state.camera.position = castDraft(position);
    })

    this.handleEvent('camera', {
      type : 'camera',
      value : this.state.camera
    });
  }

  setCameraRotation(rotation : Immutable<Vec3>) {
    this.change((state : Ugla3DState) => {
      state.camera.rotation = castDraft(rotation);
    })

    this.handleEvent('camera', {
      type : 'camera',
      value : this.state.camera
    });
  }

  setCameraFov(fov : number) {
    this.change((state : Ugla3DState) => {
      state.camera.fov = fov;
    })

    this.handleEvent('camera', {
      type : 'camera',
      value : this.state.camera
    });
  }

  setCamera(position : Immutable<Vec3>, rotation : Immutable<Vec3>, fov : number) {
    this.change((state : Ugla3DState) => {
      state.camera.position = castDraft(position);
      state.camera.rotation = castDraft(rotation);
      state.camera.fov = fov;
    })

    this.handleEvent('camera', {
      type : 'camera',
      value : this.state.camera
    });
  }

  getDefaultCamera() {
    const content = this.model.getContent();
    const defaultControls = content.defaultControls;
    const position : Vec3 = content.camera?.defaultPosition ||
      (defaultControls?.type === 'walk' && defaultControls.defaultPosition && [
        defaultControls.defaultPosition[0],
        defaultControls.height || DEFAULT_VIEW_HEIGHT,
        defaultControls.defaultPosition[1],
      ])
      || DEFAULT_POSITION;
    const rotation : Vec3 = content.camera?.defaultRotation || DEFAULT_ROTATION;
    const fov : number = content.camera?.defaultFov || DEFAULT_FOV;

    return {
      position,
      rotation,
      fov
    }
  }

  setControl(control : ControlType) {
    this.change((state : Ugla3DState) => {
      state.control = control;
    })

    this.handleEvent('control', {
      type : 'control',
      value : this.state.control
    });
  }

  selectInteractionsGroup(group : string) {
    const content = this.model.getContent();

    this.change((state : Ugla3DState) => {
      state.interactionsGroup = group;

      state.interactions = (content.interactions[group] || [])
        .map(modelInteraction => ({
          ...modelInteraction,
          marker : (modelInteraction.type === 'interface' || modelInteraction.type === 'touch') && modelInteraction.marker? content.assets[modelInteraction.marker] : undefined,
          actions : modelInteraction.actions.map(modelAction => (
            modelAction.type === 'show-label' ?
            {
              ...modelAction,
              asset : content.assets[modelAction.asset]
            } :
            modelAction.type === 'add' ?
            {
              ...modelAction,
              object : {
                ...modelAction.object,
                asset : content.assets[modelAction.object.asset]
              }
            } :
            modelAction
          ))
        }))
    })

    this.handleEvent('interactions', {
      type : 'interactions',
      value : this.state.interactions
    });
  }

  interact(interactionId : string) {
    const interaction = this.state.interactions.find(i => i.id === interactionId);

    if(interaction) {
      const actions = interaction.actions;

      actions.forEach(action => {
        switch(action.type) {
          case 'interaction-mode':
            this.selectInteractionsGroup(action.name);
          break;
          case 'show':
            this.show(castDraft(action.path));
          break;
          case 'hide':
            this.hide(castDraft(action.path));
          break;
          case 'toggle':
            this.toggle(castDraft(action.path));
          break;
          case 'add':
            this.addObject(castDraft(action.object))
          break;
          case 'remove':
            this.removeObject(castDraft(action.objectId), {regex : action.regex})
          break;
          case 'mod':
            this.setMods(action.id, castDraft(action.mods));
          break;
          case 'reset-mod':
            this.resetMods(action.id, {regex : action.regex});
          break;
        }
      })
    }
  }


  addObjectByReference(obj : SceneObject<AssetRef>) {
    const asset = this.model.assets()[obj.asset];
    if(asset) {
      this.addObject({
        ...obj,
        asset,
      })
    }
  }

  addObject(obj : SceneObject<Asset>) {
    const changeEvent = this.change((state : Ugla3DState) => {
      // Remove previous objects with same id
      state.scene = state.scene.filter(o => o.id !== obj.id);
      state.scene.push(obj);
    })

    this.handleEvent('scene', {
      type : 'scene',
      value : this.state.scene,
      added : [obj],
      removed : changeEvent.previous.scene.filter(o => o.id === obj.id),
    });

    if(obj.mods) {
      this.setMods(this.defaultModsName(obj), obj.mods);
    }
  }

  removeObject(id : string, options ?: {regex ?: boolean}) {
    const idsToRemove = options?.regex ?
      this.state.scene.map(o => o.id).filter(_id => _id.match(id)) :
      [id];

    const changeEvent = this.change((state : Ugla3DState) => {
      state.scene = state.scene.filter(o => !idsToRemove.includes(o.id));
    })

    this.handleEvent('scene', {
      type : 'scene',
      value : this.state.scene,
      added : [],
      removed : changeEvent.previous.scene.filter(o => idsToRemove.includes(o.id))
    });
  }

  show(path : ObjectPath) {
    this.change((state : Ugla3DState) => {
      state.changes.visibility.hide = state.changes.visibility.hide.filter(
        objPath => !isSameObjectPath(objPath, path)
      )
      state.changes.visibility.show = state.changes.visibility.show.filter(
        objPath => !isSameObjectPath(objPath, path)
      )
      state.changes.visibility.show.push(path);
    })

    this.handleEvent('visibility', {
      type : 'visibility',
      value : this.state.changes.visibility,
      added : {show : [path], hide : []},
      removed : {show : [], hide : [path]}
    });
  }

  hide(path : ObjectPath) {
    this.change((state : Ugla3DState) => {
      state.changes.visibility.hide = state.changes.visibility.hide.filter(
        objPath => !isSameObjectPath(objPath, path)
      )
      state.changes.visibility.show = state.changes.visibility.show.filter(
        objPath => !isSameObjectPath(objPath, path)
      )
      state.changes.visibility.hide.push(path);
    })

    this.handleEvent('visibility', {
      type : 'visibility',
      value : this.state.changes.visibility,
      added : {hide : [path], show : []},
      removed : {hide : [], show : [path]}
    });
  }

  toggle(path : ObjectPath) {
    let isHiding : boolean = false;

    this.change((state : Ugla3DState) => {
      isHiding = !!state.changes.visibility.hide.find(op => isSameObjectPath(op, path));

      state.changes.visibility.hide = state.changes.visibility.hide.filter(
        objPath => !isSameObjectPath(objPath, path)
      )
      state.changes.visibility.show = state.changes.visibility.show.filter(
        objPath => !isSameObjectPath(objPath, path)
      )

      if(isHiding) {
        state.changes.visibility.hide.push(path);
      }
      else {
        state.changes.visibility.show.push(path);
      }
    })

    this.handleEvent('visibility', {
      type : 'visibility',
      value : this.state.changes.visibility,
      added : {
        hide : isHiding ? [path] : [],
        show : isHiding ? [] : [path]
      },
      removed : {
        hide : !isHiding ? [path] : [],
        show : !isHiding ? [] : [path]
      }
    });
  }

  setMods(id : string, _mods : Modification[]) {
    const changeEvent = this.change((state : Ugla3DState) => {
      const mods = cloneDeep(_mods);
      const absolute = mods.filter(m => !m.relative);
      const relative = mods.filter(m => m.relative);

      const stored = flatten(Object.values(state.changes.mods));

      // First store absolute modification, then compute relative mods
      state.changes.mods[id] = absolute;

      relative.forEach(mod => {
        if(mod.type === "2D") {
          const previous = stored.filter((m) : m is Modification2D => m.type === '2D' && isSameObjectPath(m.path, mod.path))

          const zIndex = previous.reduce((zIndex, mod) => mod.zIndex !== undefined ? mod.zIndex : zIndex, undefined as number | undefined);
          const position = previous.reduce((position, mod) => mod.position !== undefined ? mod.position : position, undefined as Vec2 | undefined);
          const scale = previous.reduce((scale, mod) => mod.scale !== undefined ? mod.scale : scale, undefined as Vec2 | undefined);
          const rotation = previous.reduce((rotation, mod) => mod.rotation !== undefined ? mod.rotation : rotation, undefined as number | undefined);

          if(typeof mod.position !== 'undefined') {
            mod.position = [
              mod.position[0] + (position?.[0] || 0),
              mod.position[1] + (position?.[1] || 0),
            ]
          }
          if(typeof mod.rotation !== 'undefined') {
            mod.rotation += rotation || 0
          }
          if(typeof mod.scale !== 'undefined') {
            mod.scale = [
              mod.scale[0] * (scale?.[0] || 1),
              mod.scale[1] * (scale?.[1] || 1),
            ]
          }
          if(typeof mod.zIndex !== 'undefined') {
            mod.zIndex += zIndex || 0
          }
        }

        if(mod.type === '3D') {
          const previous = stored.filter((m) : m is Modification3D => m.type === '3D' && isSameObjectPath(m.path, mod.path))

          const position = previous.reduce((position, mod) => mod.position !== undefined ? mod.position : position, undefined as Vec3 | undefined);
          const scale = previous.reduce((scale, mod) => mod.scale !== undefined ? mod.scale : scale, undefined as Vec3 | undefined);
          const rotation = previous.reduce((rotation, mod) => mod.rotation !== undefined ? mod.rotation : rotation, undefined as Vec3 | undefined);

          if(typeof mod.position !== 'undefined') {
            mod.position = [
              mod.position[0] + (position?.[0] || 0),
              mod.position[1] + (position?.[1] || 0),
              mod.position[2] + (position?.[2] || 0),
            ]
          }
          if(typeof mod.rotation !== 'undefined') {
            mod.rotation = [
              mod.rotation[0] + (rotation?.[0] || 0),
              mod.rotation[1] + (rotation?.[1] || 0),
              mod.rotation[2] + (rotation?.[2] || 0),
            ]
          }
          if(typeof mod.scale !== 'undefined') {
            mod.scale = [
              mod.scale[0] * (scale?.[0] || 1),
              mod.scale[1] * (scale?.[1] || 1),
              mod.scale[2] * (scale?.[2] || 1),
            ]
          }
        }

        delete mod.relative;
        state.changes.mods[id].push(mod);
      })
    })

    this.handleEvent('mods', {
      type : 'mods',
      value : this.reduceMods(),
      added : this.reduceMods({[id] : this.state.changes.mods[id]}),
      removed : this.reduceMods({[id] : changeEvent.previous.changes.mods[id]})
      // value : flatten(Object.values(this.state.changes.mods)),
      // added : this.state.changes.mods[id],
      // removed : changeEvent.previous.changes.mods[id]
    });
  }

  resetMods(id : string, options ?: {regex ?: boolean}) {
    const idsToRemove = options?.regex ?
      Object.keys(this.state.changes.mods).filter(_id => _id.match(id)) :
      [id];

    const changeEvent = this.change((state : Ugla3DState) => {
      for(let _id of idsToRemove) {
        delete state.changes.mods[_id];
      }
    })

    this.handleEvent('mods', {
      type : 'mods',
      value : this.reduceMods(),
      added : [],
      removed : this.reduceMods(
        idsToRemove.reduce((cumul, id) => ({...cumul, [id] : changeEvent.previous.changes.mods[id]}), {})
      )
      // value : flatten(Object.values(this.state.changes.mods)),
      // added : [],
      // removed : flatten(idsToRemove.map(id => changeEvent.previous.changes.mods[id]))
    });
  }

  getMods() {
    return this.reduceMods();
  }

  private defaultModsName(obj : SceneObject<any>) {
    return `___${obj.id}-default-mods`;
  }

  // Consolidate mods to avoid hanving differents mods object for a single object
  private reduceMods(mods ?: Immutable<Record<string, Modification[]>>) {
    mods = mods || this.state.changes.mods;

    // First group all modification by target object...
    const modificationsByObject : Record<string, Immutable<Modification>[]> = {};
    flatten(Object.entries(mods).sort(([ida, _a], [idb, _b]) => ida < idb ? -1 : 1).map(([id, mods]) => mods))
    .filter(mod => !!mod)
    .forEach(mod => {
      const path = objectPathToString({objectId : mod.path.objectId, path : [...mod.path.path]});
      modificationsByObject[path] = [...(modificationsByObject[path] || []), mod];
    });

    // ...then remove mods of the wrong type (ex: 2D mods for 3D objects)...
    Object.entries(modificationsByObject).forEach(([key, mods]) => {
      modificationsByObject[key] = mods.filter(mod => mod.type === this.state.scene.find(({id}) => id === mod.path.objectId)?.asset.type);
    })

    // ...then reduce the array of modification of each object to an unique mod per object
    const reducedMods : Immutable<Modification>[] = [];
    Object.values(modificationsByObject).forEach(mods => {
      if(mods[0]?.type === '2D') {
        reducedMods.push((mods as Modification2D[]).reduce((reduced, mod) => ({...reduced, ...mod}), {} as Modification2D));
      }
      if(mods[0]?.type === '3D') {
        reducedMods.push((mods as Modification3D[]).reduce((reduced, mod) => ({...reduced, ...mod}), {} as Modification3D));
      }
    })

    return reducedMods;
  }
}

export default Ugla3DStateManager;