import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import TextInput from "../components/text-input";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import TextButton from "../components/text-button";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useState } from "react";

const EditorDefaultInteractionsGroup : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const {setDefaultInteractionGroup, clearDefaultInteractionGroup} = useModelActions('interactions');
  const [error, setError] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string | undefined>(model.defaultInteractionsGroup())

  if(!itemSelection || itemSelection.type !== 'defaultInteractionsGroup') {return null;}

  const handleChange = (name : string) => {
    setEditedName(name);
    if(model.interactionsGroupsNames().includes(name)) {
      setError(false);
      setDefaultInteractionGroup(name);
    }
    else {
      setError(true);
    }
  }

  const handleDelete = () => {
    clearDefaultInteractionGroup();
    setError(false);
    setEditedName(undefined);
  }

  return (
    <Container>
      <Typography variant="mediumTitle">Default interactions group</Typography>
      <TextInput value={editedName} onChange={handleChange} isError={error}/>
      <DeleteButtonContainer>
        <TextButton onClick={handleDelete}>Delete</TextButton>
      </DeleteButtonContainer>
    </Container>
  )
}

export default EditorDefaultInteractionsGroup;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text};
`
const DeleteButtonContainer = styled.div`
  margin-top : 1rem;
`