import styled from "styled-components";
import colors from "../../../contants/colors";
import { useModelStore } from "../../../store/store";
import ObjectEditor from "../subpanels/editor-object";
import EditorDefaultPosition from "../subpanels/editor-default-position";
import EditorDefaultRotation from "../subpanels/editor-default-rotation";
import EditorDefaultFov from "../subpanels/editor-default-fov";
import EditorControls from "../subpanels/editor-controls";
import EditorDefaultInteractionsGroup from "../subpanels/editor-default-interactions-group";
import EditorInteractions from "../subpanels/editor-interactions";
import AssetEditor from "../subpanels/editor-asset";

const Editor : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);

  return (
    <Container>
      {
        itemSelection && itemSelection.type === 'asset' ? <AssetEditor /> :
        itemSelection && itemSelection.type === 'object' ? <ObjectEditor /> :
        itemSelection && itemSelection.type === 'camera' && itemSelection.item === 'position' ? <EditorDefaultPosition /> :
        itemSelection && itemSelection.type === 'camera' && itemSelection.item === 'rotation' ? <EditorDefaultRotation /> :
        itemSelection && itemSelection.type === 'camera' && itemSelection.item === 'fov' ? <EditorDefaultFov /> :
        itemSelection && itemSelection.type === 'controls' ? <EditorControls /> :
        itemSelection && itemSelection.type === 'defaultInteractionsGroup' ? <EditorDefaultInteractionsGroup /> :
        itemSelection && itemSelection.type === 'interactions' ? <EditorInteractions /> :
        null
      }
    </Container>
  )
}

export default Editor;

const Container = styled.div`
  position: absolute;
  top : 0;
  left : 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  overflow: auto;
`

