import styled from "styled-components";
import { Tag, Vec3 } from "../../../lib/model/ugla-filetype";
import TextInput from "./text-input";
import { useState } from "react";
import TextButton from "./text-button";

interface TagsInputProps {
  value ?: Tag[];
  onChange ?: (value : Tag[]) => void;
  onDelete ?: () => void;
}

const TagsInput : React.FC<TagsInputProps> = (p) => {
  const handleDelete = () => {
    if(p.onDelete) {
      p.onChange?.([]);
      p.onDelete();
    }
  }

  const handleUpdateKey = (index : number, value : string) => {
    if(!p.value?.[index]) {return;}

    const newTags = [...p.value];
    newTags[index] = {
      key : value,
      value : p.value[index]?.value
    }

    p.onChange?.(newTags);
  }

  const handleUpdateValue = (index : number, value : string) => {
    if(!p.value?.[index]) {return;}

    const newTags = [...p.value];
    newTags[index] = {
      value : value,
      key : p.value[index]?.key
    }

    p.onChange?.(newTags);
  }

  const handleRemovePair = (index : number) => {
    if(!p.value?.[index]) {return;}

    const newTags = [...p.value];
    newTags.splice(index, 1);

    p.onChange?.(newTags);
  }

  const handleNew = () => {
    p.onChange?.([...(p.value || []), {key : 'key', value : 'value'}])
  }

  return (
    <Container>
      {
        (p.value || []).map((tag, index) => (
          <KeyValuePairContainer>
            <TextInput
              value={tag.key}
              label={'key'}
              onChange={value => handleUpdateKey(index, value)}
            />
            <TextInput
              value={tag.value}
              label={'value'}
              onChange={value => handleUpdateValue(index, value)}
            />
            <RemovePairContainer>
              <TextButton onClick={() => handleRemovePair(index)}>Remove</TextButton>
            </RemovePairContainer>
          </KeyValuePairContainer>
        ))
      }
      <ButtonsContainer>
        <TextButton onClick={handleNew}>+ Add</TextButton>&nbsp;
        {
          p.onDelete ?
            <TextButton onClick={handleDelete}>Delete all</TextButton> :
            null
        }
      </ButtonsContainer>
    </Container>
  )
}

export default TagsInput;

const Container = styled.div`
`

const ButtonsContainer = styled.div`
  margin-top : 3rem;
  text-align: right;
`

const KeyValuePairContainer = styled.div`
  margin-top : 1rem;
`

const RemovePairContainer = styled.div`
  margin-top : 0.5rem;
  text-align : right;
`