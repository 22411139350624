import styled from "styled-components"
import TextSelect from "./text-select";
import { useModelStore } from "../../../store/store";
import { AssetRef, AssetType } from "../../../lib/model/ugla-filetype";
import colors from "../../../contants/colors";

interface AssetInputProps {
  inline ?: boolean;
  value ?: AssetRef;
  onChange ?: (value : AssetRef) => void;
  label ?: string;
  type ?: AssetType;
}

const AssetInput : React.FC<AssetInputProps> = (p) => {
  const model = useModelStore().model.getContent();

  return (
    <Container className={p.inline ? 'inline' : ''}>
      <TextSelect
        label={p.label}
        options={model.assets.map((asset, index) => ({
          name : `[${asset.type}] ${asset.url.length < 15 ? asset.url : ('...' + asset.url.slice(-15))}`,
          value : `${index}`,
          type : asset.type
        })).filter(option => p.type === undefined || p.type === option.type)}
        value={p.value !== undefined ? `${p.value}` : undefined}
        onChange={(v) => p.onChange?.(parseInt(v))}
        />
    </Container>
  )
}

export default AssetInput;

const Container = styled.div`
  margin-top: 1rem;

  &.inline {
    margin-top : 0;
    margin-right : 0.5rem;
  }
`;
