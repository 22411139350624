import { useEffect, useRef, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Immutable } from 'immer';
import * as THREE from 'three'

import { Asset, SceneObject as AssetSceneObject, Modification, Modification3D, ObjectPath} from "../model/ugla-filetype";
import { useViewerActions } from "../store/hooks/use-actions";
import { Visibility } from "../model-state/ugla-3d-state-type";
import { cloneDeep } from "lodash";
import { isSameObjectPath } from "../model/ugla-types-tools";

const CLICK_TOLLERANCE = 3;

interface SceneObjectProps {
  object : Immutable<AssetSceneObject<Asset>>;
  mods : Immutable<Modification[]>;
  visibility : Immutable<Visibility>
  onClick ?: (e : any) => void;
}

const SceneObject : React.FC<SceneObjectProps> = (p) => {
  const url = p.object.asset.url;
  const objectId = p.object.id;

  const gltf = useLoader(GLTFLoader, url)
  const canvasRef = useRef();
  const {addNative, getObjectFromPath} = useViewerActions("model");
  const [previousMods, setPreviousMods] = useState<Modification3D[]>([])
  const [previousVisibilityModified, setPreviousVisibilityModified] = useState<Immutable<ObjectPath[]>>([])

  useEffect(() => {
    addNative(objectId, gltf.scene);
  }, [gltf])

  useEffect(() => {
    // Handle Visibility

    previousVisibilityModified.forEach(op => {
      const object = getObjectFromPath({objectId : op.objectId, path : [...op.path]})?.obj;
      if(object && typeof object.userData.originalVisibility === 'boolean') {
        object.visible = !!object.userData.originalVisibility;
      }
    })

    const hide   = p.visibility.hide.filter(op => op.objectId === objectId);
    const show   = p.visibility.show.filter(op => op.objectId === objectId);

    setPreviousVisibilityModified([...hide, ...show]);

    hide.forEach(op => {
      const object = getObjectFromPath({objectId : op.objectId, path : [...op.path]})?.obj;
      if(object) {
        if(typeof object.userData.originalVisibility === 'undefined') {
          object.userData.originalVisibility = object.visible;
        }
        object.visible = false;
      }
    })
    show.forEach(op => {
      const object = getObjectFromPath({objectId : op.objectId, path : [...op.path]})?.obj;
      if(object) {
        if(typeof object.userData.originalVisibility === 'undefined') {
          object.userData.originalVisibility = object.visible;
        }
        object.visible = true;
      }
    })

    // Handle Mods

    previousMods.filter((mod) : mod is Modification3D => mod.type === '3D' && mod.path.objectId === objectId).forEach(mod => {
      const object = getObjectFromPath({objectId : mod.path.objectId, path : [...mod.path.path]})?.obj;

      // Since THREE api is imperative and not declarative, reset previous modifications from THREE
      if(object) {
        if(mod.position && object.userData.originalPosition) {
          object.position.set(object.userData.originalPosition.x, object.userData.originalPosition.y, object.userData.originalPosition.z)
          delete object.userData.originalPosition;
        }

        if(mod.rotation && object.userData.originalRotation) {
          object.rotation.set(object.userData.originalRotation.x, object.userData.originalRotation.y, object.userData.originalRotation.z)
          delete object.userData.originalRotation;
        }

        if(mod.scale && !object.userData.originalScale) {
          object.scale.set(object.userData.originalScale.x, object.userData.originalScale.y, object.userData.originalScale.z)
          delete object.userData.originalScale;
        }
      }
    })


    const newMods = p.mods
      .filter((mod) : mod is Modification3D => mod.type === '3D' && mod.path.objectId === objectId)
      .map(mod => ({...mod})) // remove readonly
      .reduce((mods, nextMod) => {
      const existing = mods.find(m => isSameObjectPath(m.path, nextMod.path));
      if(existing) {
        existing.position = nextMod.position || existing.position;
        existing.rotation = nextMod.rotation || existing.rotation;
        existing.scale    = nextMod.scale    || existing.scale;
      }
      else {
        mods.push(nextMod);
      }
      return mods;
    }, [] as Modification3D[]);

    setPreviousMods(newMods);
    newMods.forEach(mod => {
      const object = getObjectFromPath({objectId : mod.path.objectId, path : [...mod.path.path]})?.obj;

      if(object) {
        if(mod.position) {
          if(!object.userData.originalPosition) {
            object.userData.originalPosition = object.position.clone();
          }
          object.position.set(...mod.position);
        }
        else {
          if(object.userData.originalPosition) {
            object.position.set(object.userData.originalPosition.x, object.userData.originalPosition.y, object.userData.originalPosition.z)
            delete object.userData.originalPosition;
          }
        }

        if(mod.rotation) {
          if(!object.userData.originalRotation) {
            object.userData.originalRotation = object.rotation.clone();
          }
          object.rotation.set(mod.rotation[0]/180*Math.PI, mod.rotation[1]/180*Math.PI, mod.rotation[2]/180*Math.PI);
        }
        else {
          if(object.userData.originalRotation) {
            object.rotation.set(object.userData.originalRotation.x, object.userData.originalRotation.y, object.userData.originalRotation.z)
            delete object.userData.originalRotation;
          }
        }

        if(mod.scale) {
          if(!object.userData.originalScale) {
            object.userData.originalScale = object.scale.clone();
          }
          object.scale.set(...mod.scale);
        }
        else {
          if(object.userData.originalScale) {
            object.scale.set(object.userData.originalScale.x, object.userData.originalScale.y, object.userData.originalScale.z)
            delete object.userData.originalScale;
          }
        }
      }
    })
  }, [p.mods, p.visibility])

  // Hande click manually as default primitive onClick fires also when mouse is moved between
  // mouse down and mouse up.
  const isDown = useRef<boolean>(false);
  const downPosition = useRef<[number, number]>([0, 0]);

  const handlePointerDown = (e : any) => {
    isDown.current = true;
    downPosition.current = [e.clientX, e.clientY];
  }

  const handlePointerUp = (e : any) => {
    if(isDown.current) {
      isDown.current = false;
      p.onClick?.(e);
    }
  }

  const handlePointerMove = (e : any) => {
    if(
      Math.abs(e.clientX - downPosition.current[0]) > CLICK_TOLLERANCE ||
      Math.abs(e.clientY - downPosition.current[1]) > CLICK_TOLLERANCE
    ) {
      isDown.current = false;
    }
  }

  return <primitive
    ref={canvasRef}
    object={gltf.scene}
    onPointerMove={handlePointerMove}
    onPointerUp={handlePointerUp}
    onPointerDown={handlePointerDown}
  />
}

export default SceneObject;
