import styled from "styled-components";
import { JSONTree } from 'react-json-tree';


import colors from "../../../contants/colors";
import { useModelStore } from "../../../store/store";
import { useUgla3dState, useUgla3dStateManager } from "../../../lib/model-state/use-model-state";
import Tabs from "../components/tabs";
import ControllerGuesser from "../subpanels/controller-guesser";

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: colors.background,
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

const Output : React.FC<{}> = (p) => {
  const content = useModelStore(state => state.model.getContent());
  const state = useUgla3dState();

  return (
    <Container>
      <Tabs names={['Model', 'State', 'Test controller']}>
        {[
          <ScrollContainer key="model"><JSONTree data={content} theme={theme} hideRoot/></ScrollContainer>,
          <ScrollContainer key="state"><JSONTree data={state}  theme={theme} hideRoot/></ScrollContainer>,
          <ControllerGuesser key="controller" />
        ]}
      </Tabs>
    </Container>
  )
}

export default Output;

const Container = styled.div`
  position : relative;
  background-color: ${colors.background};
  color : ${colors.text};
  flex : 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ScrollContainer = styled.div`
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
  background-color: ${colors.background};
  color : ${colors.text};
  /* padding : 1rem; */
  flex : 1;
  overflow : auto;
`