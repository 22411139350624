import { useState } from "react";
import { Asset, AssetType } from "../../../lib/model/ugla-filetype";
import Modal from "../components/modal"
import TextSelect from "../components/text-select";
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";
import { useModelActions } from "../../../store/hooks/use-actions";

interface AssetModalProps {
  asset ?: Asset;
  onClose ?: () => void;
  onSubmit ?: (asset : Asset) => void;
}

const AssetModal : React.FC<AssetModalProps> = (p) => {
  const [type, setType] = useState<AssetType | undefined>(p.asset?.type)
  const [url, setUrl] = useState<string | undefined>(p.asset?.url);

  const handleValidate = () => {
    if(type && url) {
      p.onSubmit?.({
        type, url
      })
    }
    p.onClose?.();
  }
  return (
    <Modal
      title={"Edition des assets"}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!type || !url} onClick={handleValidate}>Validate</TextButton>
      }
    >
      <TextSelect
        label="Type de asset"
        options={[
          {name : "3D", value : "3D"},
          {name : "2D", value : "2D"},
          {name : "Video", value : "video"},
          {name : "Skybox", value : "Skybox"}
        ]}
        value={type}
        onChange={(v) => setType(v as AssetType)}
    />
      <TextInput
        label="Url de l'asset"
        value={url}
        onChange={setUrl}
      />
    </Modal>
  )
}

export default AssetModal

