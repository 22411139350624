import styled from "styled-components";
import { Immutable } from 'immer';
import { Asset, Interaction, Modification, Modification2D, SceneObject, TouchInteraction } from "../model/ugla-filetype"
import { Visibility } from "../model-state/ugla-3d-state-type";
import { useEffect, useState } from "react";

interface InterfaceObjectProps {
  object : Immutable<SceneObject<Asset>>;
  scale ?: number;
  mods ?: Immutable<Modification[]>;
  visibility ?: Immutable<Visibility>;
  interactions ?: Immutable<Interaction<Asset>[]>
  onInteraction ?: (i : Interaction<Asset>) => void;
}

const InterfaceObject : React.FC<InterfaceObjectProps> = p => {
  const [visible, setVisible] = useState<boolean>(true);

  const interactions = p.interactions?.filter((i) : i is TouchInteraction<Asset> => i.type === 'touch' && i.path?.objectId === p.object.id) || [];

  const show   = !!p.visibility?.show?.find(op => op.objectId === p.object.id);
  const hide   = !!p.visibility?.hide?.find(op => op.objectId === p.object.id);

  useEffect(() => {
    if(hide) {
      setVisible(false);
    }
    else {
      setVisible(true);
    }
  }, [show, hide])

  if(p.object.asset.type !== '2D') {return null;}
  if(!visible) {return null;}

  const viewportScale = p.scale || 1;
  const mods = [...(p.object.mods || []), ...(p.mods || [])].filter((mod) : mod is Modification2D => mod.type === '2D' && mod.path.objectId === p.object.id);

  const zIndex = mods.reduce((zIndex, mod) => mod.zIndex !== undefined ? mod.zIndex : zIndex, 0);
  const left = mods.reduce((left, mod) => mod.position !== undefined ? mod.position[0] : left, 0);
  const top = mods.reduce((top, mod) => mod.position !== undefined ? mod.position[1] : top, 0);
  const scale = mods.reduce((scale, mod) => mod.scale !== undefined ? mod.scale : scale, undefined as Immutable<number[]> | undefined);
  const rotation = mods.reduce((rotation, mod) => mod.rotation !== undefined ? mod.rotation : rotation, 0 as number)


  const handeInteractions = () => {
    interactions.forEach(i => p.onInteraction?.(i));
  }

  return (
    <Container
      style={{
        transform : `scale(${viewportScale}, ${viewportScale})`,
        left : `${left*viewportScale}px`,
        top : `${top*viewportScale}px`,
        zIndex
      }}
      className={interactions.length ? 'interactive' : ''}
    >
      <Image
        src={p.object.asset.url}
        style={{
          ...(scale ? {width : scale[0] + 'px', height : scale[1] + 'px'} : {}),
          transform : `rotate(${rotation}deg)`,
        }}
        onClick={handeInteractions}
        onMouseDown={(e) => {e.preventDefault();}}
      />
    </Container>
  )
}

export default InterfaceObject

const Container = styled.div`
  position : absolute;
  top : 0;
  left : 0;
  pointer-events: none;
  &.interactive {
    pointer-events: all;
  }
`

const Image = styled.img`
  pointer-events: all;
  position: absolute;
`